<template>
  <div class="about">
    <el-alert v-if="errorMsg"
      :title="errorMsg"
      type="error"
      center
      effect="dark">
    </el-alert>
    <div class="box">
      <div class="title">注册帐号</div>

      <div class="context">
        <el-form
          class="form"
          :rules="mRules"
          ref="mform"
          label-position="right"
          label-width="80px"
          size="medium"
          :model="mform"
        >
          <el-form-item class="input-label" label="手机号码" prop="username">
            <el-input
              v-model="mform.username"
              autocomplete="off"
              clear="true"
            ></el-input>
          </el-form-item>
          <el-form-item class="input-label" label="登录密码" prop="pwd">
            <el-input
              v-model="mform.pwd"
              type="password"
              autocomplete="off"
              clear="true"
            ></el-input>
          </el-form-item>
          <el-form-item class="input-label" label="确认密码" prop="pwd2">
            <el-input
              v-model="mform.pwd2"
              type="password"
              autocomplete="off"
              clear="true"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="input-label input-lable-code"
            label="图形验证码"
            prop="imgcode"
          >
            <el-input
              class="input input-code"
              v-model="mform.imgcode"
              clear="true"
              maxlength="4"
              minlength="4"
              autocomplete="off"
              @keyup.enter.native="onSubmitClick('mForm')"
            ></el-input>
            <img
              class="imgcode"
              @click="onImageCodeClick"              
              :src="api + '/home/code?t=' + random"
            />
          </el-form-item>
          <el-form-item
            class="input-label input-lable-code"
            label="短信验证码"
            prop="smscode"
          >
            <el-input
              class="input input-code"
              v-model="mform.smscode"
              clear="true"
              maxlength="6"
              minlength="6"
              autocomplete="off"
              @keyup.enter.native="onSubmitClick('mform')"
            ></el-input>
            <el-button class="getSmsCode" v-click  @click="onGetSmscode('mform')">{{smsCodeText}}</el-button>
          </el-form-item>
          <div class="btn-line">
            <el-button
              class="btn"
              type="primary"
              
              @click="onSubmitClick('mform')"
              >免费注册</el-button
            >
            <!-- <el-button @click="onTestClick">测试</el-button> -->
          </div>
          <div class="links">
            <!-- <router-link to="/pub/note">业务说明</router-link> | -->
            <router-link to="/login">帐号登录</router-link> |
            <router-link to="/pub/findpwd">忘了密码</router-link>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
	  if (value === '') {
         callback(new Error('请再次输入密码'))
         // password 是表单上绑定的字段
      } else if (value !== this.mform.pwd) {
         callback(new Error('两次输入密码不一致!'))
      } else {
       	 callback()
      }
    }
    return {      
      errorMsg: '',
      api: "",
      random: 0.123,
      smsCountTime: 60,
      smsCodeText: '获取验证码',
      myinterval: false,
      mform: {
        username: "",
        pwd: "",
        pwd2: "",
        imgcode: "",
        smscode: '',
      },
      mRules: {
        username: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          }
        ],
        pwd: [
          {
            required: true,
            message: "请输入正确的密码",
            min: 6,
            max: 16,
            trigger: "blur",
          },
        ],
        pwd2: [
          {
            required: true,
            message: "请输入正确的确认密码",
            min: 6,
            max: 16,
            trigger: "blur",
          },
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],
        imgcode: [
          {
            required: true,
            message: "请输入正确的图形验证码",
            min: 4,
            max: 4,
            trigger: "blur",
          },
        ],
        smscode: [
          {
            required: true,
            message: "请输入正确的短信验证码",
            min: 6,
            max: 6,
            trigger: "blur",
          },
        ],
      },
      count: 0,
    };
  },
  created (opt) {
    this.api = this.global.api;
    this.onImageCodeClick();
  },

  methods: {
    // onTestClick() {
    //   this.post('http://localhost:9901/h_serv/home/', {}, res=>{
    //     this.toast(JSON.stringify(res))
    //   })
    // },
    onGetSmscode(formName) {   
      // console.log('hhhhh')         
      if (this.smsCountTime != 60) {
        this.toast(this.smsCountTime + '秒后才可以重发~')        
        return;
      }      
      this.$refs[formName].validateField(["username","imgcode"], errorMsg=>{
        if (errorMsg) {
          // this.toast(valid)
        } else {
          var pm = {
            client_id: 'web',
            username: this.mform.username,
            imgcode: this.mform.imgcode,
            hash: this.random,
            act: '注册帐号'
          }
          this.loading.show();
          this.post('api/pub/sendSmsCode', pm, res=>{     
            this.loading.hide();       
            if (res.code != 0) {
              this.errorMsg = '发送短信验证码失败~，' + res.msg
              return
            }
            this.toast(res.msg)
            this.smsSendFlag = true
            this.successMsg = '短信验证码已经发送到你的手机，请注意查收~'
            this.countDown()
          })
        }
      });      
    },
    onImageCodeClick() {
      this.random = this.util.uuid();
    },
    onSubmitClick(formName) {
      this.$refs[formName].validate((valid, object) => {
        if (valid) {
          this.post('/api/pub/register', this.mform, res=>{
            if (res.code == 0) {
              this.$msgbox({
                title: '注册成功',
                message: '您的帐号已经注册成功，请登录~',
                confirmButtonText: '去登录',
                cancelButtonText: '取消',                
              }).then(action=>{
                this.$router.push('/pub/login')
              })
            } else {
              this.$notify.error({
                title: '错误',
                message: res.msg
              })
            }
          })
        } else {
          
        }
      });
    },
    async countDown() {      
      this.myinterval = setInterval(()=>{
        this.smsCountTime --;
        this.smsCodeText = this.smsCountTime + '秒'
        if (this.smsCountTime <= 0) {
          clearInterval(this.myinterval)
          this.smsCodeText = '获取验证码'
          this.myinterval = false
          this.smsCountTime = 60
        }
      }, 1000);
    },
  }//-methods
}
</script>

<style scoped>
.box {
  min-height: 10vh;
  width: 35em;
  margin: 10vh auto;
  border: 1px solid #1f5081;
  border-radius: 15px;
}
.title {
  color: #fff;
  font-size: 1.5em;
  line-height: 3em;
  letter-spacing: 0.4em;
  height: 3em;
  background-color: #1f5081;
  border-radius: 15px 15px 0 0;
}
.context {
  text-align: left;
  padding: 2em;
}

.form {
  display: flex;
  flex-direction: column;
}
.input-label {
  margin-top: 0.3em;
}
.el-input {
  width: 14em;
}
.input {
  width: 18em;
}

.input-code {
  width: 6em;
  float: left;
}

.imgcode {
  float: left;
  height: 2.5em;
  margin-left: 0.3em;
}
.btn-line {
  display: flex;
}
.btn {
  width: 10em;
  margin-top: 2em;
  margin: 2em auto 0;
}

.links {
  margin-top: 20px;
  text-align: center;
}

.getSmsCode {
  margin-left: 0.3em;
}
</style>
